import React, { useState } from "react";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import { useForgetPassword } from "hooks/Forms/useForgetPassword";
import formCommonPostData from "utils/form-common-post-data";
// import { useLocation } from "@reach/router";
import {errorMessageEmail, patternEmail } from "utils/form-validation";

export default function ForgotPassword() {
  const [formStatus, setForStatus] = useState(false);
  const { mutateAsync } = useForgetPassword();
  // const location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData1 = {
      username: data.email,
      // pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() }
    mutateAsync(postData).then((_) => {
      reset();
      setForStatus(true);
    });
  };
  return (
    <div className="w-full bg-[#272727] py-20 xl:px-40 text-white font-primary">
      <div className="w-11/12 xl:w-full mx-auto flex flex-col lg:flex-row gap-10 lg:gap-16 xl:gap-56 ">
        <div>
          <h2 className="mb-5 ml-[60px]">
            <Link to="/">
              <img
                src="https://skins.tomedes.com/frontend/images/tomedes-logo.svg"
                alt=""
                className="w-44"
              />
            </Link>
          </h2>
          <div className="w-[300px] p-5 bg-black rounded-md shadow">
            <h2 className="custom-bg-h1 font-riot font-medium text-[32px] leading-10 mt-3">
              Forgot Password
            </h2>
            <p className="text-[15px] text-center mt-4 mb-5">
              Enter your login Username or Email below. You will receive an
              email with a link to reset your password.
            </p>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
              {errors.email && (
                <span className="text-red text-sm ">
                  {errorMessageEmail}
                </span>
              )}
              <input
                type="email"
                placeholder="Email Address"
                autoComplete="off"
                name="email"
                className="px-2 py-2 bg-white rounded-sm w-full mb-5 text-black"
                {...register("email", {
                  required: true,
                  pattern: patternEmail,
                })}
              />
              {formStatus ? (
                <span className="text-[#96D149] text-sm">
                  Thank you! Please check your <br />
                  inbox for a confirmation email
                </span>
              ) : (
                <></>
              )}
              <input type="hidden" id="zc_gad" name="zc_gad" value="" />
              <button className="bg-[#2664E4] py-2 flex justify-center items-center rounded-sm w-full mb-1">
                Submit
              </button>
            </form>
          </div>
        </div>
        <div>
          <h2 className="font-riot text-24 sm:text-[34px] font-medium mb-16 bg-[#0959ff80] inline-block">
            Frequently Asked Questions
          </h2>
          <div className="flex flex-col gap-9">
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">Why should I trust you?</h3>
              </div>
              <p className="text-17 font-light leading-7">
                We serve business customers from all over the globe for 8+
                years, small businesses and Fortune 500 companies alike. If you
                want to be sure, contact us for a free of charge sample.
              </p>
            </div>
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">
                  How can I be sure that I will receive my translation?
                </h3>
              </div>
              <p className="text-17 font-light leading-7">
                Dozens of translation projects are delivered to our customers
                each day. And of course we are at your service 24/7.
              </p>
            </div>
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">
                  How do I retrieve my completed translation?
                </h3>
              </div>
              <p className="text-17 font-light leading-7">
                Upon translation completion, you will receive an email
                notification from the Tomedes team. The translated document will
                be waiting for you on your account page on tomedes.com.
              </p>
            </div>
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">
                  Why do I need to pay in advance?
                </h3>
              </div>
              <p className="text-17 font-light leading-7">
                Truth be told, charging payment in advance helps us keep our
                costs lower and offer you attractive prices. Yet, Feel free to
                contact us if this option doesn't suit you.
              </p>
            </div>
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">
                  What are my payment options?
                </h3>
              </div>
              <p className="text-17 font-light leading-7">
                We accept checks, bank transfers, credit cards, Skrill & PayPal
                transactions (you can use funds in your PayPal account or send
                payment using a credit card). If you have a specific request,
                feel free to contact us.
              </p>
            </div>
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">
                  Why can't I order translation with proofreading?
                </h3>
              </div>
              <p className="text-17 font-light leading-7">
                Quality assurance is an inherent part of our job process. We
                consider QA measures, such as proofreading, to be our
                responsibility and believe you shouldn't be charged any extra
                payment for them. An expert from Tomedes team reviews every
                translation and makes sure it's only delivered in highest
                quality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
