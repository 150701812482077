import React from "react";
import Layout from "components/layout";
import ForgotPassword from "components/userAccount/forgot-password";
import Seo from "components/seo";

function index() {
  return (
    <Layout>
      <Seo title="Forgot Password - User" slug="/user/forgot-password" />
      <ForgotPassword />
    </Layout>
  );
}

export default index;
